<template>
  <div v-loading="isLoading" class="bg-white pt-2 pb-4 h-full">
    <div v-show="!mounting && hasBadt">
      <!-- <div class="flex gap-3 items-center px-3 cursor-pointer">
        <svg
          @click="()=>$router.go(-1)"
          width="32"
          height="32"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 0.25C4.62402 0.25 0.25 4.62402 0.25 10C0.25 15.376 4.62402 19.75 10 19.75C15.376 19.75 19.75 15.376 19.75 10C19.75 4.62402 15.376 0.25 10 0.25ZM10 1.75C14.5645 1.75 18.25 5.43555 18.25 10C18.25 14.5645 14.5645 18.25 10 18.25C5.43555 18.25 1.75 14.5645 1.75 10C1.75 5.43555 5.43555 1.75 10 1.75ZM9.46094 5.19531L4.65625 10L9.46094 14.8047L10.5391 13.75L7.53906 10.75H15.25V9.25H7.53906L10.5391 6.25L9.46094 5.19531Z"
            fill="#20409B"
          />
        </svg>

        <span class="fs-24 font-bold text-black">{{ $t('Quay lại') }}</span>
      </div>-->

      <div class="w-full px-3 h-full">
        <HeaderEMR
          modeView="tree"
          :getBADT="getBADT"
          @refresh="getDetailBADT"
        />
        <div
          v-if="getBADT.is_new === PATIENT_SYNC_HIS.isNew"
          class="relative flex justify-between fs-18 mb-3 pl-3 py-3 pr-10"
          style="background-color: #ffefd9"
        >
          <div
            class="flex flex-col lg:flex-row gap-4 items-center justify-between w-full"
          >
            <div class="flex items-center gap-4">
              <WarningIcon />
              <div
                class="flex-1 fs-18 break-words"
                style="word-break: break-word"
              >
                Dữ liệu
                <strong>HIS</strong> có sự thay đổi, bạn muốn cập nhật thông tin
                bệnh án điện tử không?
              </div>
            </div>
            <div class="flex items-center mr-10">
              <button
                @click="handleSyncEmr"
                class="fs-14 flex items-center justify-center border-0 focus:outline-none text-white rounded-lg h-10 px-3 py-2"
                style="background-color: #f79009"
              >
                Cập nhật tất cả
              </button>
            </div>
          </div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="absolute top-2 lg:top-1/2 transform lg:-translate-y-1/2 right-3 cursor-pointer"
          >
            <path
              d="M13.3138 11.8998L16.8488 8.36382C16.9443 8.27157 17.0205 8.16123 17.0729 8.03922C17.1253 7.91722 17.1529 7.786 17.1541 7.65322C17.1552 7.52044 17.1299 7.38876 17.0796 7.26587C17.0294 7.14297 16.9551 7.03132 16.8612 6.93742C16.7673 6.84353 16.6557 6.76928 16.5328 6.719C16.4099 6.66872 16.2782 6.64342 16.1454 6.64457C16.0126 6.64572 15.8814 6.67331 15.7594 6.72572C15.6374 6.77813 15.5271 6.85431 15.4348 6.94982L11.8988 10.4848L8.36382 6.94982C8.27157 6.85431 8.16123 6.77813 8.03922 6.72572C7.91722 6.67331 7.786 6.64572 7.65322 6.64457C7.52044 6.64342 7.38876 6.66872 7.26587 6.719C7.14297 6.76928 7.03132 6.84353 6.93742 6.93742C6.84353 7.03132 6.76928 7.14297 6.719 7.26587C6.66872 7.38876 6.64342 7.52044 6.64457 7.65322C6.64572 7.786 6.67331 7.91722 6.72572 8.03922C6.77813 8.16123 6.85431 8.27157 6.94982 8.36382L10.4848 11.8988L6.94982 15.4348C6.85431 15.5271 6.77813 15.6374 6.72572 15.7594C6.67331 15.8814 6.64572 16.0126 6.64457 16.1454C6.64342 16.2782 6.66872 16.4099 6.719 16.5328C6.76928 16.6557 6.84353 16.7673 6.93742 16.8612C7.03132 16.9551 7.14297 17.0294 7.26587 17.0796C7.38876 17.1299 7.52044 17.1552 7.65322 17.1541C7.786 17.1529 7.91722 17.1253 8.03922 17.0729C8.16123 17.0205 8.27157 16.9443 8.36382 16.8488L11.8988 13.3138L15.4348 16.8488C15.5271 16.9443 15.6374 17.0205 15.7594 17.0729C15.8814 17.1253 16.0126 17.1529 16.1454 17.1541C16.2782 17.1552 16.4099 17.1299 16.5328 17.0796C16.6557 17.0294 16.7673 16.9551 16.8612 16.8612C16.9551 16.7673 17.0294 16.6557 17.0796 16.5328C17.1299 16.4099 17.1552 16.2782 17.1541 16.1454C17.1529 16.0126 17.1253 15.8814 17.0729 15.7594C17.0205 15.6374 16.9443 15.5271 16.8488 15.4348L13.3138 11.8988V11.8998Z"
              fill="black"
            />
          </svg>
        </div>
        <TreeView />
      </div>
    </div>
    <div
      v-if="!mounting && !hasBadt"
      class="d-flex align-items-center justify-content-center py-5"
    >
      <p class="fs-16 fw-500 text-center">
        Chức này không khả dụng với Workspace hiện tại!
      </p>
    </div>
    <div
      v-if="mounting"
      class="d-flex align-items-center justify-content-center py-5"
    >
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import { WarningIcon } from '@/components/Icon'
import { APP_MODE, PATIENT_SYNC_HIS } from '../../utils/constants'
import HeaderEMR from './components/Header'
import TreeView from './components/TreeView'
import _ from 'lodash'

const tablist = [
  'master',
  'CLS',
  'ChanDoanHinhAnh',
  'BienBanHoiChan',
  'PhieuSoKet',
  'TTTQM',
  'PhieuPhauThuat',
  'PhieuKhamGayMeTruocMo',
  'PhieuGayMeHoiSuc',
  'PhieuDieuTri',
  'PhieuChamSoc',
  'Vitalsign',
  'DanhGiaDinhDuong',
  'DichVuYTe',
  'PhieuHanhChinh'
]
export default {
  name: 'ERMTreeView',
  components: { HeaderEMR, TreeView, WarningIcon },
  data () {
    return {
      searchValue: '',
      tab: 'master',
      extraSidebar: true,
      medicalRecordList: [
        {
          name: 'Bệnh án Ung bướu',
          tab: 'master',
          children: []
        },
        {
          name: 'Xét Nghiệm/ CLS (3)',
          tab: 'CLS',
          children: []
        },
        {
          name: 'Chẩn đoán hình ảnh',
          tab: 'ChanDoanHinhAnh',
          children: []
        },
        {
          name: 'Biên Bản Hội Chẩn (1)',
          tab: 'BienBanHoiChan',
          children: []
        },
        {
          name: 'Phiếu sơ kết điều trị',
          tab: 'PhieuSoKet',
          children: []
        },
        {
          name: 'Tóm tắt thông qua mổ',
          tab: 'TTTQM',
          children: []
        },
        {
          name: 'Phiếu phẫu thuật/ thủ thuật',
          tab: 'PhieuPhauThuat',
          children: []
        },
        {
          name: 'Phiếu khám gây mê trước mổ',
          tab: 'PhieuKhamGayMeTruocMo',
          children: []
        },
        {
          name: 'Phiếu gây mê hồi sức',
          tab: 'PhieuGayMeHoiSuc',
          children: []
        },
        {
          name: 'Phiếu Điều trị (2)',
          tab: 'PhieuDieuTri',
          children: []
        },
        {
          name: 'Phiếu chăm sóc',
          tab: 'PhieuChamSoc',
          children: []
        },
        {
          name: 'Phiếu theo dõi chức năng sống',
          tab: 'Vitalsign',
          children: []
        },
        {
          name: 'Đánh giá dinh dưỡng',
          tab: 'DanhGiaDinhDuong',
          children: []
        },
        {
          name: 'Phiếu công khai thuốc và dịch vụ y tế',
          tab: 'DichVuYTe',
          children: []
        },
        {
          name: 'Biểu mẫu hành chính',
          tab: 'PhieuHanhChinh',
          children: [
            {
              name: 'Chuyển tuyến',
              tab: 16
            },
            {
              name: 'Giấy hẹn khám lại',
              tab: 17
            },
            {
              name: 'Giấy chứng nhân nghỉ việc hưởng BHXH',
              tab: 18
            },
            {
              name: 'Giấy ra viện',
              tab: 19
            },
            {
              name: 'Giấy cam kết',
              tab: 20
            }
          ]
        }
      ],
      medicalRecordMain: null,
      idMedicalRecords: null,
      medical_record_code: null,
      treat_doctor: null,
      getBADT: [],
      mounting: false,
      tablist,
      PATIENT_SYNC_HIS,
      isLoading: false
    }
  },
  created () {
    if (
      _.isEmpty(this.$featureList) &&
      _.isEmpty(this.$featureWsList) &&
      _.isEmpty(this.$featureOrgList)
    ) {
      this.mounting = true
      setTimeout(() => {
        this.mounting = false
      }, 5000)
    } else {
      this.mounting = false
    }
  },
  computed: {
    hasBadt () {
      return (
        (this.$featureList && this.$featureList['badt']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['badt']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['badt']
            : false) ||
        false
      )
    }
  },
  watch: {
    $featureList (list) {
      if (!_.isEmpty(list)) {
        this.mounting = false
      }
    },
    $featureWsList (list) {
      if (!_.isEmpty(list)) {
        this.mounting = false
      }
    },
    $featureOrgList (list) {
      if (!_.isEmpty(list)) {
        this.mounting = false
      }
    }
  },
  mounted () {
    this.getDetailBADT()
  },
  methods: {
    backListBADT () {
      this.$router.go(-1)
    },
    async getDetailBADT () {
      this.idMedicalRecords = this.$route.params.id
      if (!this.idMedicalRecords) {
        return
      }
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getDetailBADT(this.idMedicalRecords)
      console.log(res)
      this.getBADT = res.data
      const medicalRecordMasterResponse = await this.getMedicalRecordMaster(
        res.data?.medical_record_master?.id
      )
      this.medicalRecordMain = medicalRecordMasterResponse || {}
      this.medical_record_code = res.data?.medical_record_code
      this.treat_doctor = res.data?.treat_doctor
      const person = {
        ...res.data.person,
        medical_record_name: res.data.medical_record_name,
        treat_doctor: res.data.treat_doctor_name,
        main_doctor: res.data.main_doctor_name,
        status: this.getBADT && this.getBADT.status,
        ref_id: res.data.ref_id,
        person_ref_id: res.data.person_ref_id
      }
      if (this.medicalRecordMain && this.medicalRecordMain.admission_room) {
        person.admission_room = this.medicalRecordMain.admission_room
      }
      if (this.medicalRecordMain && this.medicalRecordMain.admission_bed) {
        person.admission_bed = this.medicalRecordMain.admission_bed
      }
      this.$store.commit('setPerson', { person })
      this.$store.commit('setMedicalRecordMain', {
        medicalRecordMain: this.medicalRecordMain
      })
      this.$store.commit('setEmrData', {
        emrData: this.getBADT
      })
    },
    async putSyncEMR () {
      if (!this.getBADT?.ref_id) return

      try {
        this.isLoading = true
        await this.$rf
          .getRequest('DoctorRequest')
          .putSyncEMR(this.getBADT.ref_id)
        this.$toast.open({
          message: this.$t('Cập nhật thông tin bệnh án thành công'),
          type: 'success'
        })

        this.getDetailBADT()
      } catch (error) {
        this.$toast.open({
          message: this.$t('Có lỗi trong quá trình đồng bộ'),
          type: 'warning'
        })

        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleSyncEmr () {
      this.$confirm('Vui lòng xác nhận để đồng bộ thông tin bệnh án từ HIS', {
        confirmButtonText: 'Xác nhận'
      })
        .then((_) => {
          this.putSyncEMR()
        })
        .catch((_) => {})
    },
    async getMedicalRecordMaster (medicalRecordMasterID) {
      if (!medicalRecordMasterID) return

      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getDetailMedicalRecordMaster(medicalRecordMasterID)

        return response.data
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
