<template>
  <div class="bg-white relative cs-height" id="ElectronicMedicalRecords">
    <ButtonVersion @onShowEMRVersion="handleShowVersion" />
    <div>
      <div
        class="sticky w-full top-0 flex flex-col md:flex-row justify-content-between gap-2 py-2 text-base font-semibold d-print-none z-10"
        style="background-color:#f9fbfd;
        border-bottom: 1px solid #d5d5d5"
      >
        <div class="flex flex-col md:flex-row gap-2 md:gap-3 md:items-center px-3 cursor-pointer">
          <svg
            @click="handleGoToEMR"
            width="32"
            height="32"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 0.25C4.62402 0.25 0.25 4.62402 0.25 10C0.25 15.376 4.62402 19.75 10 19.75C15.376 19.75 19.75 15.376 19.75 10C19.75 4.62402 15.376 0.25 10 0.25ZM10 1.75C14.5645 1.75 18.25 5.43555 18.25 10C18.25 14.5645 14.5645 18.25 10 18.25C5.43555 18.25 1.75 14.5645 1.75 10C1.75 5.43555 5.43555 1.75 10 1.75ZM9.46094 5.19531L4.65625 10L9.46094 14.8047L10.5391 13.75L7.53906 10.75H15.25V9.25H7.53906L10.5391 6.25L9.46094 5.19531Z"
              fill="#20409B"
            />
          </svg>
          <div class="flex flex-col md:flex-row gap-2 md:gap-4 md:items-center justify-center">
            <div class="fs-20 font-bold text-blue-900">{{ getDate() }}</div>

            <div
              v-if="medicalRecordMain.update_by_info"
              class="flex gap-1 items-center cursor-pointer"
            >
              <div :set="updateByInfo=medicalRecordMain.update_by_info" style="max-width: 32px">
                <ImageBox
                  v-if="updateByInfo.avatar"
                  class="avatar avatar-sm avatar-rounded object-cover object-top border"
                  avatar
                  :source="getImageURL(updateByInfo.avatar)"
                  :alt="updateByInfo.name"
                />
                <span
                  v-if="!updateByInfo||!updateByInfo.avatar"
                  class="avatar avatar-sm avatar-rounded bg-pri text-white"
                >
                  {{
                  updateByInfo.name.charAt(0) ||
                  updateByInfo.email.charAt(0)
                  }}
                </span>
              </div>
              <span class="font-bold">{{updateByInfo.name }}</span>
            </div>
          </div>

          <!-- <span class="fs-24 font-bold text-black">{{ $t('Quay lại') }}</span> -->
        </div>
        <button
          class="btn border-none text-black px-3 mx-2 h-10"
          @click="handleTriggerPrint()"
          :disabled="loading"
          style="border: 1px solid #d5d5d5"
        >
          <div class="loading" v-if="loading">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.8332 4.66667H13.9998V0.5H3.99984V4.66667H3.1665C1.78317 4.66667 0.666504 5.78333 0.666504 7.16667V12.1667H3.99984V15.5H13.9998V12.1667H17.3332V7.16667C17.3332 5.78333 16.2165 4.66667 14.8332 4.66667ZM5.6665 2.16667H12.3332V4.66667H5.6665V2.16667ZM12.3332 13.8333H5.6665V10.5H12.3332V13.8333ZM13.9998 10.5V8.83333H3.99984V10.5H2.33317V7.16667C2.33317 6.70833 2.70817 6.33333 3.1665 6.33333H14.8332C15.2915 6.33333 15.6665 6.70833 15.6665 7.16667V10.5H13.9998Z"
              fill="#20419B"
            />
            <path
              d="M13.9998 8.41667C14.4601 8.41667 14.8332 8.04357 14.8332 7.58333C14.8332 7.1231 14.4601 6.75 13.9998 6.75C13.5396 6.75 13.1665 7.1231 13.1665 7.58333C13.1665 8.04357 13.5396 8.41667 13.9998 8.41667Z"
              fill="#20419B"
            />
          </svg>
          {{ $t('multidisciplinary_board.lbl_print') }}
        </button>
      </div>
      <div class="w-full h-detail px-3 overflow-y-scroll relative">
        <TreatmentCardVersion
          :medicalRecordMain="medicalRecordMain"
          :medical_record_code="medical_record_code"
          :treat_doctor="treat_doctor"
          :getBADT="getBADT"
          :person="personInfo"
          :ctaPrint="ctaPrint"
          @refresh="getEMRVersionDetail"
          @onLoading="(isLoading)=>loading=isLoading"
        />
      </div>
    </div>
  </div>
</template>

  <script>
import TreatmentCardVersion from '@/components/ElectronicMedicalRecords/EMRVersion/TreatmentCardVersion'
import { mapState } from 'vuex'
import appUtils from '../../utils/appUtils'
import ImageBox from '@/components/Common/Image.vue'
import ButtonVersion from '@/components/ElectronicMedicalRecords/EMRVersion/ButtonVersion.vue'

export default {
  name: 'TreeView',
  components: {
    TreatmentCardVersion,
    ImageBox,
    ButtonVersion
  },
  data () {
    return {
      medicalRecordMain: {},
      medical_record_code: null,
      treat_doctor: null,
      getBADT: {},
      personInfo: {},
      emr_version_id: null,
      loading: false,
      ctaPrint: 0
    }
  },

  computed: {
    ...mapState({
      personStore: state => state.person
    })
  },
  watch: {
    $route: {
      deep: true,
      handler () {
        this.emr_version_id = this.$route.params.id

        this.getEMRVersionDetail()
      }
    }
  },
  mounted () {
    this.emr_version_id = this.$route.params.id
    this.getEMRVersionDetail()
  },
  methods: {
    handleSelected () {
      if (
        this.$route.query.tab &&
        this.tablist.indexOf(this.$route.query.tab) !== -1
      ) {
        if (this.$route.query.bmhc) {
          this.bmhcType = Number(this.$route.query.bmhc)
        }

        this.tab = this.$route.query.tab
      } else {
        this.bmhcType = null
        this.tab = 'master'
        this.$router.replace({ query: { tab: 'master' } })
      }
    },
    getDetail (show) {
      this.tab = show
      if (this.tab !== this.$route.query.tab) {
        this.$router.replace({ query: { tab: this.tab } })
      }
    },
    backListBADT () {
      this.$router.go(-1)
    },
    onOpenSidebar () {
      this.extraSidebar = !this.extraSidebar
    },
    async getEMRVersionDetail () {
      if (!this.emr_version_id) return

      try {
        this.loading = true
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getEMRVersionDetail(this.emr_version_id)

        this.medicalRecordMain = response.data

        this.medicalRecordMain.attachments = this.medicalRecordMain
          ?.meta_attachments
          ? JSON.parse(this.medicalRecordMain?.meta_attachments)
          : []

        this.medicalRecordMain.files = this.medicalRecordMain?.meta_files
          ? JSON.parse(this.medicalRecordMain?.meta_files)
          : []

        this.medicalRecordMain.inouts = this.medicalRecordMain?.meta_inouts
          ? JSON.parse(this.medicalRecordMain?.meta_inouts)
          : []

        const medicalRecordInfo = response.data?.medical_record_infor || {}
        this.medical_record_code = medicalRecordInfo?.medical_record_code

        this.getBADT = medicalRecordInfo

        const person = {
          ...medicalRecordInfo.persons_infor,
          medical_record_name: medicalRecordInfo.medical_record_name,
          treat_doctor: medicalRecordInfo.treat_doctor_name,
          main_doctor: medicalRecordInfo.main_doctor_name,
          medical_record_id: medicalRecordInfo.id,
          status: this.getBADT && this.getBADT.status,
          ref_id: medicalRecordInfo.ref_id,
          person_ref_id: medicalRecordInfo.person_ref_id,
          guardian: this.personStore?.guardian || {}
        }
        this.personInfo = person

        if (this.medicalRecordMain && this.medicalRecordMain.admission_room) {
          person.admission_room = this.medicalRecordMain.admission_room
        }
        if (this.medicalRecordMain && this.medicalRecordMain.admission_bed) {
          person.admission_bed = this.medicalRecordMain.admission_bed
        }
        //
        if (!this.medicalRecordMain?.career_name) {
          this.medicalRecordMain.career_name = person.career
        }
        if (!this.medicalRecordMain?.ethnic_name) {
          this.medicalRecordMain.ethnic_name = person.ethnic_name
        }
        if (!this.medicalRecordMain?.nationality_name) {
          this.medicalRecordMain.nationality_name = person.foreigner
        }
        if (!this.medicalRecordMain?.address_detail) {
          this.medicalRecordMain.address_detail = person.address
        }
        if (!this.medicalRecordMain?.address_wards_name) {
          this.medicalRecordMain.address_wards_name = person.address_wards_name
        }
        if (!this.medicalRecordMain?.address_district_name) {
          this.medicalRecordMain.address_district_name =
            person.address_district_name
        }
        if (!this.medicalRecordMain?.address_province_name) {
          this.medicalRecordMain.address_province_name =
            person.address_province_name
        }
        if (!this.medicalRecordMain?.work_at) {
          this.medicalRecordMain.work_at = person.work_at
        }
        if (!this.medicalRecordMain?.insurance_type) {
          this.medicalRecordMain.insurance_type = person.insurance_type
        }
        if (!this.medicalRecordMain?.insurance_expire) {
          this.medicalRecordMain.insurance_expire = person.insurance_expire
        }
        if (!this.medicalRecordMain?.insurance_number) {
          this.medicalRecordMain.insurance_number = person.insurance_number
        }
        if (!this.medicalRecordMain?.emergency_contact) {
          this.medicalRecordMain.emergency_contact = person.emergency_contact
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    handleGoToEMR () {
      this.$router.replace({
        name: 'ERMTreeView',
        params: {
          id: this.medicalRecordMain?.medical_record_id
        }
      })
    },
    getDate () {
      if (!this.medicalRecordMain?.created_at) return

      return window
        .moment(this.medicalRecordMain?.created_at)
        .format('HH:mm - DD/MM/YYYY')
    },
    handleTriggerPrint () {
      this.ctaPrint++
    },
    getImageURL (avatar) {
      if (avatar.includes('https')) return avatar
      return appUtils.getImageURL(avatar)
    },
    handleShowVersion () {
      const params = {
        medical_record_id: this.medicalRecordMain?.medical_record_id,
        page_num: 1,
        page_size: 10000,
        order: 'desc',
        sort_by: 'created_at'
      }
      this.$store.dispatch('emrStore/getEMRVersion', params)
    }
  }
}
</script>

  <style lang="scss" scoped>
.h-detail {
  // height: calc(100vh - 14rem);
  height: 100%;
}

.cs-height {
  height: calc(100vh - 61px);
  overflow-y: scroll;
}
</style>
